<template>
    <div class="fvt">
        <VisualisationSimulation :tool="tool">
            <FlybyArrowLegend />
            <VisualisationObjectLegend />
        </VisualisationSimulation>
        <VisualisationAxesViewer />
        <FlybyTimeline />
        <VisualisationRecorder />
        <VisualisationSettings :tool="tool" />
        <FlybyObjectsConfiguration :tool="tool" />
    </div>
</template>

<script>
import FlybyObjectsConfiguration from '@/components/fvt/FlybyObjectsConfiguration'
import VisualisationSimulation from '@/components/common/visualisation/Simulation'
import VisualisationRecorder from '@/components/common/visualisation/Recorder'
import VisualisationObjectLegend from '@/components/common/visualisation/ObjectLegend'
import VisualisationAxesViewer from '@/components/common/visualisation/AxesViewer'
import VisualisationSettings from '@/components/common/visualisation/Settings'
import FlybyTimeline from '@/components/fvt/FlybyTimeline'
import CommonActions from '@/store/common/common-actions'
import FlybyArrowLegend from './FlybyArrowLegend.vue'
import VisualisationService from '@/services/visualisation.service'
import CommonGetters from '@/store/common/common-getters'
import TimeFrameService from '@/services/timeframe.service'
import PopupService from '@/services/popup.service'
import ObjectsService from '@/services/objects.service'

export default {
    name: 'FlybyVisualisationTool',
    components: {
    FlybyObjectsConfiguration,
    VisualisationSimulation,
    VisualisationRecorder,
    VisualisationObjectLegend,
    VisualisationAxesViewer,
    VisualisationSettings,
    FlybyTimeline,
    FlybyArrowLegend
},
    props: {
        tool: String,
    },                     
    methods: {
        async focusInSelectedCloseApproach() {            
            const timeframe = window.store.getters[CommonGetters.timeFrame];
            TimeFrameService.setTimeFrame(timeframe.start, timeframe.end);            
            TimeFrameService.setFlybyTimeframe(timeframe.start, timeframe.end);            
            ObjectsService.setFinalAsteroidsList(window.store.getters[CommonGetters.finalAsteroidsList]);
            try {
                await VisualisationService.searchCloseApproaches();
                const closeApproachList = VisualisationService.getCloseApproaches();                
                if (closeApproachList.length > 0) {
                    VisualisationService.setSelectedCloseApproach(closeApproachList[0]);                                              
                }
            } catch (error) {
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message: error.message,
                });
                return;
            }     
        },
    },
    mounted() {        
        this.$store.dispatch(CommonActions.setTool, 'fvt');
        this.$store.dispatch(CommonActions.setToolTitle, this.$keys.titles.fvt);
        const closeApproachDetected = window.store ? window.store.getters[CommonGetters.closeApproachDetected] : null;       
        if (closeApproachDetected) {
            this.focusInSelectedCloseApproach();
            this.$store.dispatch(CommonActions.setCloseApproachDetected, false);                                                                 
        }
    }
}
</script>

<style lang="scss">
// @import "@/styles/common/visualisation.scss"; duplicated in OVT
@import "@/styles/fvt/general.scss";
</style>
