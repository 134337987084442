<template>
    <div class="fvt__objects-configuration" :class="{ hidden: !show }">
        <div class="fvt__objects-configuration__title">
            <span>{{ $t.searchForCloseApproaches }}</span>
            <Tooltip position="bottom" :tooltipText="$keys.tooltips.closeApproachesSelection"
                ><i class="icon-info"
            /></Tooltip>
        </div>
        <div class="fvt__objects-configuration__title-vertical">
            {{ $t.searchForCloseApproaches }}
        </div>
        <div
            class="fvt__objects-configuration__close"
            :class="{ open: !show }"
            @click="toggleSelectObjectsPanel()"
        ></div>
        <ul class="fvt__objects-tabs" :class="{ 'fvt__objects-tabs--hidden': !show }">
            <li
                class="fvt__tab"
                :class="{ 'fvt__tab--active': objectsMode === 'database' }"
                @click="changeObjectsMode('database')"
            >
                <span class="fvt__tab-label fvt__tab-label--first">{{ $t.databaseObjects }}</span>
            </li>
            <li
                class="fvt__tab"
                :class="{ 'fvt__tab--active': objectsMode === 'user-defined' }"
                @click="changeObjectsMode('user-defined')"
            >
                <span class="fvt__tab-label">{{ $t.userDefinedObjects }}</span>
            </li>
        </ul>
        <template v-if="objectsMode === 'database'">
            <div class="fvt__input-parameters">
                <FlybyMainBodySelector />
                <FlybyTimeframeSelection />
                <FlybyObjectsSelection />
                <FlybySearch />
                <p v-if="isNoApproachFoundMsgVisible" class="fvt__error-msg" v-html="`${$t.noCloseApproachesDetected}`"></p>
            </div>
            <div class="fvt__close-approaches">
                <FlybyCloseApproaches />
            </div>
            <div v-if="closeApproaches.length" class="fvt__focus-on">
                <button
                    v-if="!focused"
                    class="link"
                    :class="{ disabled: !selectedCloseApproach || !isNonSyntheticObjectFlybyCalculated }"
                    @click="focusOnObject()"
                >
                    <i class="icon-crosshair" /><span>{{ $t.focusOnSelectedObject }}</span>
                </button>
                <button v-else class="link" @click="focusOnMainBody()">
                    <i class="icon-maximize" /><span>{{ $t.focusOnTheCenter }}</span>
                </button>
                <p class="ovt__objects-table__right-mouse-button units">
                    <Tooltip position="left" :tooltipText="$t.useRightMouseButton"
                        ><div class="right-mouse-button__icon"
                    /></Tooltip>
                </p>
            </div>
        </template>
        <template v-if="objectsMode === 'user-defined'">
            <div class="fvt__input-parameters">
                <FlybyMainBodySelector />
                <SyntheticObjects tool="fvt" />
                <FlybySearch mode="user-defined" :isBtnDisabled="isComputeSyntheticObjectDisabled"/>
                <p v-if="isFlybyAboveLimit" class="fvt__error-msg">{{ $t.flybyAboveLimit }}</p>
                <p v-if="isSyntheticObjectFlybyCalculated" class="fvt__info">{{ $t.closeApproachCalculated }}</p>
                <div v-if="isSyntheticObjectFlybyCalculated" class="fvt__focus-on">
                    <button
                        v-if="!focused"
                        class="link"
                        @click="focusOnObject()"
                    >
                        <i class="icon-crosshair" /><span>{{ $t.focusOnObject }}</span>
                    </button>
                    <button v-else class="link" @click="focusOnMainBody()">
                        <i class="icon-maximize" /><span>{{ $t.focusOnTheCenter }}</span>
                    </button>
                    <p class="ovt__objects-table__right-mouse-button units">
                        <Tooltip position="left" :tooltipText="$t.useRightMouseButton"
                            ><div class="right-mouse-button__icon"
                        /></Tooltip>
                    </p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import VisualisationService from "@/services/visualisation.service";
import Tooltip from "@/components/common/Tooltip";
import FlybyTimeframeSelection from "@/components/fvt/FlybyTimeframeSelection";
import FlybyMainBodySelector from "@/components/fvt/FlybyMainBodySelector";
import FlybyObjectsSelection from "@/components/fvt/FlybyObjectsSelection";
import FlybySearch from "@/components/fvt/FlybySearch";
import FlybyCloseApproaches from "@/components/fvt/FlybyCloseApproaches";
import SyntheticObjects from "@/components/common/SyntheticObjects";
import SyntheticObjectsService from "@/services/synthetic-objects.service";

export default {
    name: "FlybyObjectsTable",
    components: {
        Tooltip,
        FlybyTimeframeSelection,
        FlybyMainBodySelector,
        FlybyObjectsSelection,
        FlybySearch,
        FlybyCloseApproaches,
        SyntheticObjects,
    },
    data() {
        return {
            objectsMode: "database",
        };
    },
    computed: {
        show() {
            return VisualisationService.getSelectObjectsShow();
        },
        closeApproaches() {
            return VisualisationService.getCloseApproaches();
        },
        selectedCloseApproach() {
            return VisualisationService.getSelectedCloseApproach();
        },
        focused() {
            return VisualisationService.getOrbitViewerFocusedOnObject();
        },
        isComputeSyntheticObjectDisabled() {
            if (!this.isSyntheticObjectAdded) {
                return true;
            }
            return this.isSyntheticObjectFlybyCalculated;
        },
        isSyntheticObjectAdded() {
            return SyntheticObjectsService.getSyntheticObjectsList().length;
        },
        isNoApproachFoundMsgVisible() {
            return VisualisationService.getIsNoApproachFoundMsgVisible();
        },
        isFlybyAboveLimit() {
            return SyntheticObjectsService.getIsSyntheticObjectFlybyAboveLdLimit();
        },
        isSyntheticObjectFlybyCalculated() {
            return SyntheticObjectsService.checkIsSyntheticObjectFlybyCalculated();
        },
        isNonSyntheticObjectFlybyCalculated() {
            return SyntheticObjectsService.checkIsNonSyntheticObjectFlybyCalculated();
        }
    },
    methods: {
        toggleSelectObjectsPanel() {
            VisualisationService.setSelectObjectsShow(!this.show);
        },
        focusOnObject() {
            VisualisationService.focusOnSelectedObject(true);
        },
        focusOnMainBody() {
            VisualisationService.focusOnCenter(true);
        },
        changeObjectsMode(modeName) {
            this.objectsMode = modeName;
        },
    },
};
</script>

<style></style>
