<template>
    <div class="synthetic-object">
        <p class="synthetic-object__title">{{ $t.addUserDefinedObject }}</p>
        <div class="synthetic-object__wrapper">
            <div class="synthetic-object__column synthetic-object__column--parameters">
                <p class="synthetic-object__subtitle">
                    {{ $t.provideObjectValues }}
                </p>
                <form novalidate class="synthetic-object__inputs-wrapper">
                    <template v-for="input in inputsList">
                        <CustomInput
                            :key="input.id"
                            v-if="!input.isAlwaysVisible"
                            :inputData="input"
                            :value="parameters[input.id]"
                            v-model="parameters[input.id]"
                            @input="updateInputValue($event, input.id, input.type)"
                            @dateChange="updateDateValue($event, input.id)"
                            :loading="loading"
                            :ref="input.id"
                            :isError="errors[input.id]"
                            :isPerihelionAphelionError="errors['perihelionAphelion']"
                        />
                    </template>
                </form>
            </div>
            <div class="synthetic-object__column synthetic-object__column--search" v-if="isDefaultSyntheticInputsList">
                <p class="synthetic-object__subtitle">
                    <Tooltip position="right" :style="{marginLeft: '200px'}" :tooltipText="$t.objectsColorInList"><i class="icon-info" :style="{ color: '#0F84DD', marginLeft: '182px', marginBottom: '-15px' }" /></Tooltip>
                    {{ $t.copyExistingObject }}
                </p>
                <div class="synthetic-object__search search">
                    <span class="search__wrapper">
                        <i class="search__icon icon-search" />
                        <input
                            class="search__input"
                            ref="objectInput"
                            type="text"
                            v-model="name"
                            :placeholder="$t.startTyping"
                            :class="{ loading: loadingAsteroidsList }"
                            @keyup="findAsteroidByPhrase($event)"
                        />
                    </span>
                    <ul class="search__list">
                        <li
                            class="search__item"
                            :class="{
                                'search__item--active': isAvailableSelected(item),
                            }"
                            v-for="(item, index) of availableAsteroidsList"
                            :style="{ color: getItemColor(item)}"
                            :key="index"
                            @click="onAvailableSelect(item)"
                        >
                            {{ displayName(item) }}
                        </li>
                    </ul>
                    <div class="search__action">
                        <button
                            type="button"
                            class="search__button secondary"
                            :class="{
                                disabled: !availableSelected || loading,
                                loading: loading,
                            }"
                            :disabled="!availableSelected || loading"
                            @click="handleCopyObjectValues()"
                        >
                            <span
                                class="search__button-content"
                                :class="{
                                    'search__button-content--hidden': loading,
                                }"
                                >&lt; {{ $t.copyValues }}</span
                            >
                            <span v-if="loading" class="search__button-content--loading">{{ $t.copyInProgress }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="synthetic-object__footer">
            <button
                type="button"
                class="synthetic-object__button link"
                :class="{ disabled: loading || !isAnyParameterProvided }"
                @click="clearAllParameters()"
                :disabled="loading || !isAnyParameterProvided"
            >
                {{ $t.clearAll }}
            </button>
            <button
                type="button"
                class="synthetic-object__button cancel"
                :class="{ disabled: loading }"
                @click="cancel()"
                :disabled="loading"
            >
                {{ $t.cancel }}
            </button>
            <button
                type="button"
                class="synthetic-object__button primary"
                :class="{
                    disabled: loading || !isParametersProvided || isFormError,
                }"
                @click="saveObject()"
                :disabled="loading || !isParametersProvided || isFormError"
            >
                {{ $t.saveObject }}
            </button>
        </div>
    </div>
</template>

<script>
import Tooltip from '@/components/common/Tooltip'
import CustomInput from "@/components/common/CustomInput";
import ObjectsService from "@/services/objects.service";
import PopupService from "@/services/popup.service";
import SynodicCalculationService from "@/services/synodic-calculation.service";
import SynodicChartService from "@/services/synodic-chart.service";
import SyntheticObjectsService from "@/services/synthetic-objects.service";
import UtilsService from "@/services/utils.service";
import VisualisationService from "@/services/visualisation.service";
import config from '@/constants/config' 
import moment from 'moment';

export default {
    name: "PopupSyntheticObjectConfiguration",
    components: {
        CustomInput,
        Tooltip,
    },
    props: {
        config: Object,
    },
    data() {
        return {
            name: "",
            typingDelay: null,
            availableSelected: null,
            parameters: {},
            errors: {},
        };
    },
    computed: {
        availableAsteroidsList() {
            return ObjectsService.getAvailableAsteroidsList();
        },

        loadingAsteroidsList() {
            return ObjectsService.getLoadingAsteroidsList();
        },

        inputsList() {
            return this.isDefaultSyntheticInputsList ? this.defaultSyntheticInputsList : this.fvtSyntheticInputsList;
        },

        defaultSyntheticInputsList() {
            return SyntheticObjectsService.getDefaultSyntheticInputsList();
        },

        fvtSyntheticInputsList() {
            return SyntheticObjectsService.getFvtSyntheticInputsList();
        },

        isParametersProvided() {
            return UtilsService.checkObjectPropertiesHasValue({
                ...this.parameters,
            });
        },

        isFormError() {
            return Object.values(this.errors).some((error) => error);
        },

        isAnyParameterProvided() {
            return UtilsService.checkAnyObjectPropertyHasValue({
                ...this.parameters,
            });
        },

        isDefaultSyntheticInputsList() {
            return this.currentTool !== "fvt";
        },

        currentTool() {
            return VisualisationService.getTool();
        },

        isEditMode() {
            return this.config.editedObjectIndex || this.config.editedObjectIndex === 0;
        },

        loading() {
            return VisualisationService.loadingObjectsEphemerides().length;
        },
    },
    methods: {
        getItemColor(item) {
            const settings = config.visualisation.settings;
            if (item.code == "non-NEO") {
                return settings.nonNEAcolor;
            } else if (item.sourceType.name == "NEOCP"){
                return settings.NEOCPcolor;
            }
        },
        displayName(item) {
            return ObjectsService.objectNumberAndName(item);
        },

        findAsteroidByPhrase($event) {
            this.availableSelected = null;
            clearTimeout(this.typingDelay);
            this.typingDelay = setTimeout(() => {
                const phrase = $event.target.value;
                ObjectsService.findAllAsteroidByPhrase(phrase);
            }, 400);
        },

        onAvailableSelect(item) {
            this.availableSelected = this.isAvailableSelected(item) || item.sourceType.name == "NEOCP" ? null : item;
        },

        isAvailableSelected(item) {
            return !this.availableSelected ? false : this.availableSelected.name === item.name;
        },

        async handleCopyObjectValues() {
            if (!this.availableSelected || this.loading) {
                return;
            }
            const designator = await SyntheticObjectsService.getObjectDesignator(this.availableSelected);
            const objectInformation = await SyntheticObjectsService.getRealObjectParameters(designator);
            const errorObject = objectInformation.errors.length ? objectInformation.errors[0] : null;
            const objectData = objectInformation.data;
            const objectParameters = objectData.length ? objectData[0] : null;
            objectParameters &&
                (objectParameters.catalogueNumber = objectParameters.catalogueNumber ?? objectParameters.designator);
            this.stopLoading(designator);
            if (errorObject || !objectParameters) {
                this.resetParameters();
                errorObject && this.showPopupInfo(errorObject.message + " " + errorObject.name, "error");
                return;
            }
            this.updateSelectedObjectParameters(objectParameters);
        },

        fillEditedObjectParameters() {
            const objectsList = UtilsService.deepCopy(this.getObjectsList());
            const object = objectsList[this.config.editedObjectIndex];
            if (object.referenceEpoch) {
                object.referenceEpoch = this.julianToDateObject(object.referenceEpoch);
            }
            if (object.pericentrePassage) {
                object.pericentrePassage = this.julianToDateObject(object.pericentrePassage);
            }
            Object.keys(this.parameters).forEach((parameterKey) =>
                this.setParameter(parameterKey, object[parameterKey])
            );
        },

        julianToDateObject(julian) {
            const julianDate = julian < 240000.5 ? julian + 2400000.5 : julian;
            // extracted from julian-date library because of missing roundings
            return new Date(Math.ceil(((julianDate + 0.5 - 2440588).toFixed(8) * 1000 * 60 * 60 * 24)));
        },

        resetParameters() {
            Object.keys(this.parameters).forEach((parameterKey) => this.setParameter(parameterKey, null));
        },

        updateSelectedObjectParameters(objectParameters) {
            const updatedParameters = SyntheticObjectsService.getUpdatedObjectParameters(objectParameters);
            updatedParameters.referenceEpoch = UtilsService.julianToDateObject(updatedParameters.referenceEpoch);
            const copiedObjectName = this.getCopiedObjectName();
            updatedParameters.objectName = copiedObjectName;
            Object.keys(updatedParameters).forEach((key) => this.setParameter(key, updatedParameters[key]));
        },

        getCopiedObjectName() {
            const objectsNames = this.getObjectsList().map(({ objectName }) => objectName.trim().toLowerCase());
            const objectDefaultName = `${this.displayName(this.availableSelected)} - copy`;
            let objectCopyNumber = 1;
            while (
                objectsNames.includes(
                    objectCopyNumber === 1
                        ? objectDefaultName.toLowerCase()
                        : `${objectDefaultName} ${objectCopyNumber}`.toLowerCase()
                )
            ) {
                objectCopyNumber++;
            }
            return objectCopyNumber === 1 ? objectDefaultName : `${objectDefaultName} ${objectCopyNumber}`;
        },

        setParameters() {
            this.isDefaultSyntheticInputsList ? this.setDefaultParameters() : this.setFvtParameters();
            this.setDefaultObjectName();
        },

        setDefaultParameters() {
            const parameters = Object.keys(this.defaultSyntheticInputsList);
            parameters.forEach((parameterName) => this.setParameter(parameterName, null));
        },

        setFvtParameters() {
            const parameters = Object.keys(this.fvtSyntheticInputsList);
            parameters.forEach((parameterName) => this.setParameter(parameterName, null));
        },

        setDefaultObjectName() {
            const objectsNames = this.getObjectsList().map(({ objectName }) => objectName.trim().toLowerCase());
            let objectNumber = objectsNames.length + 1;
            while (objectsNames.includes(`my object ${objectNumber}`)) {
                objectNumber++;
            }
            this.setParameter("objectName", `my object ${objectNumber}`);
        },

        setParameter(parameterName, parameterValue) {
            this.parameters[parameterName] = parameterValue;
            this.validate(parameterName, parameterValue);
        },

        updateInputValue(event, parameterId, dataType) {
            if (dataType === 'date') {
                return;
            }
            this.setParameter(parameterId, event.target.value);
        },

        updateDateValue(event, parameterId) {
            this.setParameter(parameterId, event);
        },

        validate(inputId, inputValue) {
            const inputData = this.inputsList[inputId];
            const { validation } = inputData;
            if (!validation) {
                return;
            }
            const { schema } = validation;
            if (!schema) {
                return;
            }
            if (schema === "unique") {
                this.validateUniqueFieldValue(inputId, inputValue, validation);
            }
            if (schema === "limitedNumber") {
                this.validateLimitedNumber(inputId, inputValue, validation);
            }
            if (inputId === 'perihelionDistance' || inputId === 'aphelionDistance') {
                this.validatePerihelionAndAphelion();
            }
        },

        validateUniqueFieldValue(fieldId, fieldValue, validationData) {
            const {maxLength} = validationData;
            const objectsList = this.getObjectsList().filter((_, idx) => this.config.editedObjectIndex !== idx);
            const valuesList = objectsList.map((object) => object[fieldId].trim());
            fieldValue = typeof fieldValue === "string" ? fieldValue.trim() : fieldValue;
            if (maxLength && fieldValue && fieldValue.length > maxLength) {
                fieldValue = fieldValue.substring(0, maxLength);
                this.parameters[fieldId] = fieldValue;
            }
            if (fieldValue !== null && valuesList.includes(fieldValue)) {
                this.toggleFieldError(fieldId);
                return;
            }
            this.toggleFieldError(fieldId, false);
        },

        validateLimitedNumber(inputId, inputValue, validationData) {
            const {min, max, decimal} = validationData;
            if (inputValue === null) {
                this.toggleFieldError(inputId, false);
                return;
            }
            if (inputValue === '') {
                this.toggleFieldError(inputId);
                return;
            }
            if (isNaN(parseFloat(inputValue))) {
                this.toggleFieldError(inputId);
                return;
            }
            this.toggleFieldError(inputId, false);
            if (inputValue > max) {
                this.parameters[inputId] = max;
                return;
            }
            if (inputValue < min && (min <= 0 || min >= 1 )) {
                this.parameters[inputId] = min;
                return;
            }
            if (+inputValue !== 0 && inputValue < min && min > 0 && min < 1) {
                this.parameters[inputId] = min;
                return;
            }
            if (+inputValue === 0 && min > 0 && min < 1) {
                this.toggleFieldError(inputId);
                return;
            }
            const valueParts = inputValue.toString().split(/[.,]/);
            const integerPart = valueParts[0];
            let decimalPart = valueParts[1] || "";
            if (!decimalPart.length) {
                this.parameters[inputId] = integerPart;
                return;

            }
            if (decimal && decimalPart.length > decimal) {
                decimalPart = decimalPart.slice(0, decimal);
            }
            const formattedValue = integerPart + '.' + decimalPart;
            this.parameters[inputId] = formattedValue;
        },

        validatePerihelionAndAphelion() {
            const perihelionValue = this.parameters['perihelionDistance'];
            const aphelionValue = this.parameters['aphelionDistance'];
            if ((!perihelionValue && perihelionValue !== 0) || (!aphelionValue && aphelionValue !== 0)) {
                this.toggleFieldError('perihelionAphelion', false);
                return;
            }
            if (+aphelionValue > +perihelionValue) {
                this.toggleFieldError('perihelionAphelion', false);
                return;
            }
            this.toggleFieldError('perihelionAphelion');
        },

        toggleFieldError(fieldId, isError = true) {
            this.errors[fieldId] = isError;
        },

        stopLoading(designator) {
            const loadingObjects = SynodicCalculationService.loadingObjectsEphemerides();
            loadingObjects.splice(loadingObjects.indexOf(designator), 1);
        },

        showPopupInfo(message, type = "warning") {
            PopupService.show({
                component: "PopupInfo",
                type: type,
                message: message,
            });
        },

        clearAllParameters() {
            this.resetParameters();
        },

        cancel() {
            this.$emit("cancel");
        },

        saveObject() {
            if (this.loading || !this.isParametersProvided) {
                return;
            }
            this.isEditMode ? this.updateObjectData() : this.addNewObject();
            this.$emit("submit");
        },

        updateObjectData() {
            const objectsList = this.getObjectsList();
            const objectData = this.getTrimedParametersValues();
            const previousObjectDesignator = objectsList[this.config.editedObjectIndex].designator;
            objectData.designator = SyntheticObjectsService.createSyntheticObjectDesignator(objectData.objectName);
            objectsList[this.config.editedObjectIndex] = objectData;
            this.updateObjectsList(objectsList);
            this.currentTool === "ovt" && this.adjustOvtToolFeatures(objectData.designator, previousObjectDesignator);
            this.currentTool === "sovt" && this.adjustSovtToolFeatures(objectData.designator, previousObjectDesignator);
            this.currentTool === "fvt" && this.adjustFvtToolFeatures();
        },

        adjustOvtToolFeatures(currentDesignator, previousObjectDesignator) {
            const { checkedObjectsList, perturbedObjectsList, isObjectChecked, isObjectSelected, isObjectPerturbed } =
                SyntheticObjectsService.getOvtObjectsInformationsByDesignator(previousObjectDesignator);
            isObjectPerturbed &&
                this.adjustOvtPerturbedObjects(perturbedObjectsList, previousObjectDesignator, currentDesignator);
            isObjectSelected && this.adjustOvtSelectedObject(currentDesignator);
            isObjectChecked &&
                this.adjustOvtCheckedObjects(
                    checkedObjectsList,
                    previousObjectDesignator,
                    currentDesignator,
                    isObjectPerturbed
                );
        },

        adjustOvtPerturbedObjects(perturbedObjectsList, previousObjectDesignator, designator) {
            perturbedObjectsList[perturbedObjectsList.indexOf(previousObjectDesignator)] = designator;
        },

        adjustOvtSelectedObject(designator) {
            VisualisationService.setSelectedObjectName(designator);
        },

        adjustOvtCheckedObjects(checkedObjectsList, previousObjectDesignator, designator, isObjectPerturbed) {
            checkedObjectsList[checkedObjectsList.indexOf(previousObjectDesignator)] = designator;
            VisualisationService.removeObject(`${previousObjectDesignator}${isObjectPerturbed ? "_perturbed" : ""}`);
            VisualisationService.addObject(designator, isObjectPerturbed);
        },

        adjustSovtToolFeatures(currentDesignator, previousObjectDesignator) {
            const {
                calculatedObjectList,
                calculatedObjectIndex,
                perturbedObjectsList,
                perturbedObjectIndex,
                isObjectSelected,
                isObjectVisible,
                objectIndexInVisibleList,
            } = this.getSovtObjectsInformation(previousObjectDesignator);
            const isPerturbedObjectOrbit = perturbedObjectIndex !== -1;
            if (isPerturbedObjectOrbit) {
                perturbedObjectsList[perturbedObjectIndex] = currentDesignator;
                SynodicCalculationService.setPerturbedSynodicOrbitList(perturbedObjectsList);
            }
            if (calculatedObjectIndex === -1) {
                isObjectSelected && VisualisationService.selectSynodicObject(currentDesignator);
                return;
            }
            this.adjustSovtCalculatedObject(
                calculatedObjectList,
                calculatedObjectIndex,
                currentDesignator,
                previousObjectDesignator,
                isObjectVisible,
                objectIndexInVisibleList,
                isObjectSelected,
                isPerturbedObjectOrbit
            );
        },

        getSovtObjectsInformation(previousObjectDesignator) {
            const calculatedObjectList = UtilsService.deepCopy(
                SynodicCalculationService.getCalculatedRealSynodicObjectsList()
            );
            const calculatedObjectIndex = UtilsService.findItemIndexInObjectList(
                "designator",
                previousObjectDesignator,
                calculatedObjectList
            );
            const perturbedObjectsList = UtilsService.deepCopy(
                SynodicCalculationService.getPerturbedSynodicOrbitList()
            );
            const perturbedObjectIndex = perturbedObjectsList.indexOf(previousObjectDesignator);
            const { isObjectSelected, isObjectVisible, objectIndexInVisibleList } =
                SynodicCalculationService.getSynodicObjectVisibilityInfo(previousObjectDesignator);
            return {
                calculatedObjectList,
                calculatedObjectIndex,
                perturbedObjectsList,
                perturbedObjectIndex,
                isObjectSelected,
                isObjectVisible,
                objectIndexInVisibleList,
            };
        },

        adjustSovtCalculatedObject(
            calculatedObjectList,
            calculatedObjectIndex,
            currentDesignator,
            previousObjectDesignator,
            isObjectVisible,
            objectIndexInVisibleList,
            isObjectSelected,
            isPerturbedObjectOrbit
        ) {
            calculatedObjectList[calculatedObjectIndex].designator = currentDesignator;
            calculatedObjectList[calculatedObjectIndex].objectData =
                SyntheticObjectsService.getSyntheticObjectParameters(currentDesignator);
            calculatedObjectList[calculatedObjectIndex].objectData.catalogueNumber = currentDesignator;
            SynodicCalculationService.setCalculatedRealSynodicObjectsList(calculatedObjectList);
            if (isObjectVisible) {
                const visibleRealSynodicObjectsList = SynodicCalculationService.getVisibleRealSynodicObjectsList();
                visibleRealSynodicObjectsList.splice(objectIndexInVisibleList, 1);
                SynodicCalculationService.setVisibleRealSynodicObjectsList(visibleRealSynodicObjectsList);
                SynodicCalculationService.removeExistingSpaceObject(previousObjectDesignator, isPerturbedObjectOrbit);
                SynodicChartService.removeSynodicOrbitObjectChartData(previousObjectDesignator);
                isObjectSelected && VisualisationService.selectSynodicObject(currentDesignator);
                !isObjectSelected && SynodicCalculationService.showCalculatedInvisibleObject(currentDesignator);
            }
        },

        adjustFvtToolFeatures() {
            SyntheticObjectsService.setIsSyntheticObjectFlybyAboveLdLimit(false);
            const selectedObjectData = VisualisationService.getSelectedCloseApproach();
            const isSyntheticObjectSelected = selectedObjectData && !!selectedObjectData.objectName;
            isSyntheticObjectSelected && VisualisationService.computeUserDefinedCloseApproach();
        },

        addNewObject() {
            const objectsList = this.getObjectsList();
            const objectData = this.getTrimedParametersValues();
            objectData.designator = SyntheticObjectsService.createSyntheticObjectDesignator(objectData.objectName);
            objectsList.push(objectData);
            this.updateObjectsList(objectsList);
        },

        getTrimedParametersValues() {
            const updatedParameters = {};
            Object.keys(this.parameters).forEach((parameterKey) => {
                const parameterValue = this.parameters[parameterKey];
                updatedParameters[parameterKey] =
                    typeof parameterValue === "string" ? parameterValue.trim() : parameterValue;
                if (this.inputsList[parameterKey] && this.inputsList[parameterKey].type === 'number') {
                    updatedParameters[parameterKey] = parseFloat(updatedParameters[parameterKey]);
                }
            });
            if (updatedParameters.referenceEpoch) {
                updatedParameters.referenceEpoch = (this.dateToJulian(new Date(updatedParameters.referenceEpoch)) - 2400000.5).toFixed(8) * 1;
            }
            if (updatedParameters.pericentrePassage) {
                updatedParameters.pericentrePassage = (this.dateToJulian(new Date(updatedParameters.pericentrePassage)) - 2400000.5).toFixed(8) * 1;
            }
            return updatedParameters;
        },

        dateToJulian(date) {
            // extracted from julian-date library because of missing roundings
            return (new Date(new moment.utc(date)).valueOf() / (1000 * 60 * 60 * 24)).toFixed(8) - 0.5 + 2440588;
        },

        updateObjectsList(objectsList) {
            this.isDefaultSyntheticInputsList
                ? SyntheticObjectsService.setSyntheticDefaultObjectsList(objectsList)
                : SyntheticObjectsService.setSyntheticFvtObjectsList(objectsList);
        },

        getObjectsList() {
            return this.isDefaultSyntheticInputsList
                ? [...SyntheticObjectsService.getSyntheticDefaultObjectsList()]
                : [...SyntheticObjectsService.getSyntheticFvtObjectsList()];
        },
    },
    created() {
        this.setParameters();
    },
    mounted() {
        this.isEditMode && this.fillEditedObjectParameters();
        ObjectsService.setAvailableAsteroidsList([]);
        this.$refs.objectName[0].$refs.objectName.focus();
    },
};
</script>

<style lang="scss">
@import "@/styles/common/popup-synthetic-object-configuration.scss";
</style>
