<template>
    <div class="sovt__objects-configuration" :class="{ hidden: !show }">
        <div class="sovt__objects-configuration__title">
            {{ $t.selectObjects }}
        </div>
        <div class="sovt__objects-configuration__title-vertical">
            {{ $t.selectObjects }}
        </div>
        <div class="ovt__objects-configuration__subtitle">{{ $t.databaseObjects }}</div>
        <div
            class="sovt__objects-configuration__close"
            :class="{ open: !show }"
            @click="toggleSelectObjectsPanel()"
        ></div>
        <SynodicOrbitObjectsList />
        <SyntheticObjects tool="sovt" />
        <div class="sovt__objects-inputs">
            <template v-for="input in inputsList">
                <SynodicOrbitObjectsCustomInput
                    :key="input.id"
                    v-if="input.isAlwaysVisible"
                    :inputData="input"
                    :value="parameters[input.id]"
                    v-model="parameters[input.id]"
                    @input="updateInputValue($event, input.id, input.type)"
                />
            </template>
        </div>
        <SynodicOrbitCalculate
            :isParametersProvided="isParametersProvided"
            :isMagnitudeValid="isMagnitudeValid"
            :isNewDataProvided="isCalculatedObjectChange"
            @calculate="handleCalculate()"
        />
        <SynodicOrbitFocusSelector />
    </div>
</template>

<script>
import SynodicOrbitCalculate from "@/components/sovt/SynodicOrbitCalculate";
import SynodicOrbitFocusSelector from "@/components/sovt/SynodicOrbitFocusSelector";
import SynodicOrbitObjectsCustomInput from "@/components/sovt/SynodicOrbitObjectsCustomInput";
import SynodicOrbitObjectsList from "@/components/sovt/SynodicOrbitObjectsList";
import SyntheticObjects from "@/components/common/SyntheticObjects";
import PopupService from "@/services/popup.service";
import SpacekitService from "@/services/spacekit.service";
import SynodicCalculationService from "@/services/synodic-calculation.service";
import SyntheticObjectsService from "@/services/synthetic-objects.service";
import UtilsService from "@/services/utils.service";
import VisualisationService from "@/services/visualisation.service";
import Keys from "@/constants/keys";

export default {
    name: "SynodicOrbitObjectsConfigurator",
    components: {
        SynodicOrbitCalculate,
        SynodicOrbitFocusSelector,
        SynodicOrbitObjectsCustomInput,
        SynodicOrbitObjectsList,
        SyntheticObjects,
    },
    data() {
        return {
            parameters: {
                referenceEpoch: null,
                perihelionDistance: null,
                aphelionDistance: null,
                inclination: null,
                rightAscension: null,
                perihelionArgument: null,
                meanAnomaly: null,
                absoluteMagnitude: null,
                slopeParameter: null,
                magnitudeLimit: 18,
            },
            currentObjectData: null,
            inputsList: VisualisationService.getSynodicObjectsInputsList(),
        };
    },
    computed: {
        show() {
            return VisualisationService.getSelectObjectsShow();
        },

        displaySelects() {
            return VisualisationService.getSelects();
        },

        isParametersProvided() {
            return UtilsService.checkObjectPropertiesHasValue({
                ...this.parameters,
            });
        },

        isMagnitudeValid() {
            const magnitudeValue = this.parameters.magnitudeLimit.toString();
            const isMagnitudeInRange = +magnitudeValue >= -30 && +magnitudeValue <= 32;
            return !!magnitudeValue && isMagnitudeInRange;
        },

        isCalculatedObjectChange() {
            const calculatedObject = this.getCalculatedObjectData();
            if (!calculatedObject) {
                return true;
            }
            return +calculatedObject.magnitudeLimit !== +this.parameters.magnitudeLimit;
        },

        selectedSynodicObjectName() {
            return VisualisationService.getSelectedSynodicObjectName();
        },
    },
    methods: {
        async handleCalculate() {
            SpacekitService.removeDetectionPolarObjects();
            SpacekitService.removeOrbitRangeObject();
            const calculatedObject = this.setSelectedObjectData();
            const isOrbitAvailable = await SynodicCalculationService.createSynodicOrbit(
                this.parameters,
                calculatedObject.objectData,
                this.selectedSynodicObjectName
            );
            if (isOrbitAvailable) {
                this.updateCalculatedRealSynodicObjectsList(calculatedObject);
                SynodicCalculationService.updateVisibleRealSynodicObjectsList(this.selectedSynodicObjectName);
                return;
            }
            SynodicCalculationService.showNoPerturbedInfo(this.selectedSynodicObjectName);
            SpacekitService.removeDetectionPolarObjects();
            SpacekitService.removeOrbitRangeObject();
            const perturbedOrbitList = UtilsService.deepCopy(SynodicCalculationService.getPerturbedSynodicOrbitList());
            const objectIndex = perturbedOrbitList.indexOf(this.selectedSynodicObjectName);
            objectIndex !== -1 && perturbedOrbitList.splice(objectIndex, 1);
            SynodicCalculationService.setPerturbedSynodicOrbitList(perturbedOrbitList);
        },

        setSelectedObjectData() {
            const { magnitudeLimit } = this.parameters;
            return {
                designator: this.selectedSynodicObjectName,
                objectData: this.currentObjectData,
                magnitudeLimit,
            };
        },

        updateCalculatedRealSynodicObjectsList(calculatedObject) {
            const calculatedObjects = UtilsService.deepCopy(
                SynodicCalculationService.getCalculatedRealSynodicObjectsList()
            );
            const calculatedObjectIndex = UtilsService.findItemIndexInObjectList(
                "designator",
                this.selectedSynodicObjectName,
                calculatedObjects
            );
            calculatedObjectIndex === -1
                ? calculatedObjects.push(calculatedObject)
                : (calculatedObjects[calculatedObjectIndex] = calculatedObject);
            SynodicCalculationService.setCalculatedRealSynodicObjectsList(calculatedObjects);
        },

        async setObjectProperties() {
            const calculatedObjectData = this.getCalculatedObjectData();
            try {
                let objectParameters = null;
                let errorObject = null;
                if (calculatedObjectData && calculatedObjectData.objectData) {
                    objectParameters = calculatedObjectData.objectData;
                } else {
                    const isSyntheticObject = SyntheticObjectsService.isSyntheticObject(this.selectedSynodicObjectName);
                    if (!isSyntheticObject) {
                        const objectInformation = await SynodicCalculationService.getSelectedSynodicObjectParameters();
                        errorObject = objectInformation.errors.length ? objectInformation.errors[0] : null;
                        const data = objectInformation.data;
                        objectParameters = data.length ? data[0] : null;
                        objectParameters &&
                            (objectParameters.catalogueNumber =
                                objectParameters.catalogueNumber ?? objectParameters.designator);
                        this.stopLoading();
                    } else {
                        objectParameters = SyntheticObjectsService.getSyntheticObjectDataByDesignator(
                            this.selectedSynodicObjectName
                        );
                        objectParameters.catalogueNumber = objectParameters.designator;
                    }
                }
                if (errorObject || !objectParameters) {
                    this.currentObjectData = null;
                    this.resetParameters();
                    errorObject && this.showPopupInfo(errorObject.message + " " + errorObject.name, "error");
                    return;
                }
                this.updateSelectedObjectParameters(objectParameters, calculatedObjectData);
                calculatedObjectData
                    ? this.handleCalculatedObjectUpdate(calculatedObjectData)
                    : this.handleNonCalculatedObjectSelect();
                if (!calculatedObjectData) {
                    const parameters = { ...this.parameters };
                    // eslint-disable-next-line
                    const { magnitudeLimit, ...neededParameters } = parameters;
                    const isNeededParameters = UtilsService.checkObjectPropertiesHasValue(neededParameters);
                    isNeededParameters && this.handleCalculate();
                }
            } catch (error) {
                this.showPopupInfo(Keys.t.errorOccured);
                this.stopLoading();
                const message = UtilsService.prepareErrorMessage(error);
                console.warn(message);
            }
        },

        updateSelectedObjectParameters(objectParameters, calculatedObjectData) {
            const updateObjectParameters = !objectParameters.objectName
                ? this.setMissingParameters(objectParameters)
                : SyntheticObjectsService.getSyntheticObjectParameters(objectParameters.designator);
            if (!updateObjectParameters) {
                return;
            }
            this.currentObjectData = updateObjectParameters;
            const parametersMap = SynodicCalculationService.getObjectParamtersMap();
            Object.keys(parametersMap).forEach((key) =>
                this.updateParameter({
                    id: parametersMap[key],
                    value: updateObjectParameters[key],
                })
            );
            calculatedObjectData &&
                this.updateParameter({
                    id: "magnitudeLimit",
                    value: calculatedObjectData.magnitudeLimit,
                });
        },

        setMissingParameters(objectParameters) {
            !objectParameters.g && (objectParameters.g = 0.25);
            if (!objectParameters.aphelion && objectParameters.a && objectParameters.e) {
                objectParameters.aphelion = objectParameters.a * (1 + objectParameters.e);
            }
            return objectParameters;
        },

        async handleCalculatedObjectUpdate(calculatedObjectData) {
            await SynodicCalculationService.createSynodicOrbit(
                this.parameters,
                calculatedObjectData.objectData,
                this.selectedSynodicObjectName
            );
            SynodicCalculationService.updateVisibleRealSynodicObjectsList(this.selectedSynodicObjectName);
        },

        handleNonCalculatedObjectSelect() {
            const isSyntheticObject = SyntheticObjectsService.isSyntheticObject(this.selectedSynodicObjectName);
            const parameters = { ...this.parameters };
            // eslint-disable-next-line
            const { magnitudeLimit, ...neededParameters } = parameters;
            const isNeededParameters = UtilsService.checkObjectPropertiesHasValue(neededParameters);
            //isNeededParameters && !isSyntheticObject && this.showPopupInfo(Keys.t.objectDataRetrived, "success");
            !isNeededParameters && !isSyntheticObject && this.showPopupInfo(Keys.t.missingParameters);
            SpacekitService.handleSynodicOrbitHiglight();
        },

        getCalculatedObjectData() {
            const designator = this.selectedSynodicObjectName;
            const calculatedObjectList = SynodicCalculationService.getCalculatedRealSynodicObjectsList();
            return UtilsService.findItemInObjectList("designator", designator, calculatedObjectList);
        },

        showPopupInfo(message, type = "warning") {
            PopupService.show({
                component: "PopupInfo",
                type: type,
                message: message,
            });
        },

        stopLoading() {
            const loadingObjects = SynodicCalculationService.loadingObjectsEphemerides();
            loadingObjects.splice(loadingObjects.indexOf(this.selectedSynodicObjectName), 1);
        },

        updateInputValue(event, parameterId, dataType) {
            const value = dataType !== "date" ? event.target.value : event;
            this.setParameter(parameterId, value);
        },

        updateParameter(parameterData) {
            const { id, value } = parameterData;
            this.setParameter(id, value);
        },

        setParameter(parameterId, parameterValue) {
            this.parameters[parameterId] = parameterValue;
            this.validate(parameterId, parameterValue);
        },

        validate(parameterId, parameterValue) {
            const inputData = this.inputsList[parameterId];
            if (!inputData) {
                return;
            }
            const { validation } = inputData;
            if (!validation) {
                return;
            }
            const { schema } = validation;
            if (!schema) {
                return;
            }
            if (schema === "limitedNumber") {
                this.validateLimitedNumber(parameterId, parameterValue, validation);
            }
        },

        validateLimitedNumber(parameterId, parameterValue, validationData) {
            const { min, max, decimal } = validationData;
            if (parameterValue === null || parameterValue === "" || isNaN(parseFloat(parameterValue))) {
                return;
            }
            if (parameterValue > max) {
                this.parameters[parameterId] = max;
                return;
            }
            if (parameterValue < min) {
                this.parameters[parameterId] = min;
                return;
            }
            const valueParts = parameterValue.toString().split(/[.,]/);
            const integerPart = valueParts[0];
            let decimalPart = valueParts[1] || "";
            if (!decimalPart.length) {
                this.parameters[parameterId] = integerPart;
                return;
            }
            if (decimalPart.length > decimal) {
                decimalPart = decimalPart.slice(0, decimal);
            }
            const formattedValue = integerPart + "." + decimalPart;
            this.parameters[parameterId] = parseFloat(formattedValue);
        },

        resetParameters() {
            Object.keys(this.parameters)
                .filter((key) => key !== "magnitudeLimit")
                .forEach((key) => this.updateParameter({ id: key, value: null }));
        },

        toggleSelectObjectsPanel() {
            VisualisationService.setSelectObjectsShow(!this.show);
        },
    },
    watch: {
        selectedSynodicObjectName() {
            if (this.selectedSynodicObjectName) {
                this.resetParameters();
                this.setObjectProperties();
                return;
            }
            SynodicCalculationService.setSynodicActiveObject(null);
            SpacekitService.handleSynodicOrbitHiglight();
            this.resetParameters();
        },
    },
    mounted() {
        if (this.selectedSynodicObjectName) {
            this.resetParameters();
            this.setObjectProperties();
        }
    },
};
</script>

<style></style>
