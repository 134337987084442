<template>
    <div v-if="!finalAsteroidsList.length && longSearchTimeFrame()" class="fvt__configure-objects__section no-objects-selected input units">
        <i class="icon-clock" />
        <span>{{ $t.searchingForAllObjectsWillTakeAWhile }}</span>
    </div>
    <div v-if="finalAsteroidsList.length" class="fvt__configure-objects__section objects-list input">
        <div class="input-label">
            <span>{{ $t.findCloseApproachesFor }}:</span>
            <Tooltip position="left" :tooltipText="`${$t.noCloseApproachesForComents}`"><i class="icon-info" /></Tooltip>
        </div>
        <ul class="objects-list__inner">
            <li class="objects-list__item"  v-for="(item, index) of finalAsteroidsList" :key="index">
                <span :class="{units: isComet(item)}" :style="{ color: getItemColor(item)}" >{{ displayName(item) }}</span>
            </li>
        </ul>
    </div>
    <div class="fvt__configure-objects__box"> 
        <button class="link" @click="configureObjects()">
            <i class="icon-settings" />
            <span v-if="!finalAsteroidsList.length" >{{ $t.selectObjects + ' (' + $t.optional + ')' }}</span>
            <span v-else >{{ $t.configureYourOwnListOfObjects }}</span>
        </button>
    </div>
</template>

<script>
import PopupService from '@/services/popup.service'
import ObjectsService from '@/services/objects.service'
import TimeFrameService from '@/services/timeframe.service'
import Tooltip from '@/components/common/Tooltip'
import UtilsService from '@/services/utils.service'
import VisualisationService from '@/services/visualisation.service'
import config from '@/constants/config'

export default {
    name: 'FlybyObjectsSelection',
    components: {
        Tooltip
    },
    computed: {
        finalAsteroidsList() {
            return ObjectsService.getFinalAsteroidsList();
        },
        flybyTimeFrame() {
            return TimeFrameService.getFlybyTimeframe();
        }
    },
    methods: {
        getItemColor(item) {
            const settings = config.visualisation.settings;
            if (item.code == "non-NEO") {
                return settings.nonNEAcolor;
            } else if (item.sourceType.name == "NEOCP"){
                return settings.NEOCPcolor;
            }
        },
        configureObjects() {
            PopupService.show({
                component: 'PopupObjectsConfiguration',
            });
        },
        displayName(object) {
            return ObjectsService.objectNumberAndName(object);
        },
        isComet(object) {
            return ObjectsService.isCometWithoutObjectGroup(object);
        },
        longSearchTimeFrame() {
            if (this.flybyTimeFrame && this.flybyTimeFrame.start && this.flybyTimeFrame.end) {
                const yearsLimit = 10;
                const start = UtilsService.julianToTimestamp(this.flybyTimeFrame.start);
                const end = UtilsService.julianToTimestamp(this.flybyTimeFrame.end);
                const difference = UtilsService.dateDifferenceInDays(new Date(end), new Date(start));
                return difference >= (365 * yearsLimit);
            } else {
                return false;
            }
        }
    },
    watch: {
        finalAsteroidsList() {
            VisualisationService.setIsNoApproachFoundMsgVisible(false);
        }
    }
}
</script>

<style>

</style>