export default {
    tools: ['opt', 'scd', 'ovt', 'fvt', 'sovt'],
    toolsEnabled: ['opt', 'scd', 'ovt', 'fvt', 'sovt'],
    api: {
        // restUrl: location.host.indexOf('localhost') > -1 ? 'http://neotools-api.elecnor-deimos.com/rest' : '/rest',
        restUrl: location.host.indexOf('localhost') > -1 ? 'http://10.254.2.46/rest' : '/rest',
        findAsteroidByPhraseUrl: '/asteroid/findAsteroidByPhrase',
        findAsteroidByNameUrl: '/asteroid/findAsteroidByName',
        findObservatoryByPhraseUrl: '/observatory/findObservatoryByPhrase',
        filtering: '/opt/filtering',
        loadToolStateUrl: '/opt/loadToolState',
        saveToolStateUrl: '/opt/saveToolState',
        calculate: '/opt/calculate',
        calculationResult: '/opt/getCalculationResult',
        cancelCalculation: '/opt/cancelCalculation',
        longTermUrl: '/opt/longterm',
        shortTermUrl: '/opt/shortterm',
        getBackgroundObjects: '/scd/area',
        getObjectPathAngle: '/scd/pathangle',
        getTraceObject: '/scd/trace',
        getTraceSyntheticObject: '/scd/trace_user_defined',
        getSmallobjects: '/nov/smallobjects/', // group, family, tax, plrisk
        getEphemerides: '/nov/ephemerides/', // /keplerian?name=[ASTEROID_NUMBER_OR_NAME], /perturbed?name=[ASTEROID_NUMBER_OR_NAME],
        getOvtSyntheticObjectPerturbed: '/nov/ephemerides/ovt_user_defined',
        mainBodies: '/fvt/mainBodies',
        closeApproaches: '/fvt/close_approaches',
        flyby: '/fvt/flyby', // ?name=99942&date=20290413&center=earth
        shape: '/fvt/shape_rotation', // ?names=99942,2
        synodic: '/nov/synodic',
        impactors: '/asteroid/impactors',
        closeApproachesSyntheticObject: '/nov/ephemerides/fvt_user_defined',
    },
    previousObservationUrl: {
        default: 'http://neo.ssa.esa.int/search-for-asteroids?tab=obs&des=',
        NEOCP: 'http://cgi.minorplanetcenter.net/cgi-bin/showobsorbs.cgi?obs=y&Obj=',
        JPL: 'https://ssd.jpl.nasa.gov/sbdb.cgi?sstr=',
        JPLComet: 'https://ssd.jpl.nasa.gov/tools/sbdb_lookup.html#/?des=',
    },
    map: {
        zoom: 11,
        iconSettings: {
            anchor: [0.5, 41],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: '/static/images/marker.png'
        },
        animationDuration: 333,
    },
    timeFrame: {
        shortTermThreshold: 30,
        step: 1,
        unit: 'd',
        stepMinimum: {
            m: 10,
            h: 1,
            d: 1,
        }
    },
    pagination: {
        initialLoadSize: 250,
        size: 20,
    },
    calculation: {
        dataPointsLimit: 10000,
        getResultsDelay: 1000,
    },
    ephemerides: {
        defaultColumns: 'JD_UTC,AST_RA,AST_DEC,MAG,APPMOT_RA,APPMOT_DEC',
        sunmoonColumns: 'MOON_ALT',
        downloadFileName: 'NEOTools_listOfEphemerides',
    },
    shortTermPlots: {
        sunAltLimit: -18,
    },
    longtermPlots: {
        defaultPlots: {
            MOOEL: 'degrees',
            ELON: 'degrees',
            SKY_MOV: 'arcsecmin',
            MAG: 'mag',
            AST_GLAT: 'degrees',
            DEN_STARS: 'starsarcmin2',
        },
        operators: {
            MOOEL: 'gt',
            ELON: 'gt',
            SKY_MOV: 'lt',
            MAG: 'lt',
            AST_GLAT: 'gt',
            DEN_STARS: 'lt',
        },
        colors: {
            default: '#0F84DD',
            MOOEL: '#A81A3A',
            ELON: '#FDC82F',
            SKY_MOV: '#0098DB',
            MAG: '#E37222',
            AST_GLAT: '#008542',
            DEN_STARS: '#B200FF',
        },
        timestampDensity: 48,
        downloadFileName: 'NEOTools_longTermPlots',
        chartAdvancedFeatures: true,
    },
    skychart: {
        objectTracking: true,
        dmsFormat: false,        
        options: {            
            displaySettings: 'objectNames,objectNamesSize,telescopeParameters,trackDescription,starlight,constellations,regions,milkyWayBackground,dsos,crosshair,landscape,atmosphere,compass,horizontalGrid,equatorialGrid,eclipticGrid,uncertaintyRegion,uncertaintyDensity,uncertaintyFactor,objectTrajectories,trailPointSymbol,trailPointSize',                    
            export: 'image,PDF',
            button: 'displaySettings,invertColors,export',
            slider: 'fov',
        },       
        settings: {
            displaySettings: {
                objectNames: {
                    type: 'checkbox',                    
                    value:true,
                    color: '#FFFFFF',  // white    
                },
                objectNamesSize: {
                    type: 'slider',                                        
                    value: 2.25,                    
                    min: 1,
                    max: 30,
                    step: 0.25, 
                    label: false,
                    parent: 'objectNames'      
                },
                telescopeParameters: {
                    type: 'checkbox',                   
                    value: true
                },
                trackDescription: {
                    type: 'checkbox',                   
                    value: false
                },
                starlight: {
                    type: 'checkbox',                   
                    value: false
                },
                constellations: {
                    type: 'checkbox',                   
                    value: false
                },
                regions: {
                    type: 'checkbox',                   
                    value: false
                },
                milkyWayBackground: {
                    type: 'checkbox',                   
                    value: true
                },
                dsos: {
                    type: 'checkbox',                   
                    value: false
                },
                crosshair: {
                    type: 'checkbox',                   
                    value: true
                },
                landscape: {
                    type: 'checkbox',                   
                    value: true
                },
                atmosphere: {
                    type: 'checkbox',                   
                    value: true
                },
                compass: {
                    type: 'checkbox',                   
                    value: true
                },
                horizontalGrid: {
                    type: 'checkbox',                   
                    value: false
                },
                equatorialGrid: {
                    type: 'checkbox',                   
                    value: false
                },
                eclipticGrid: {
                    type: 'checkbox',                   
                    value: false
                },
                objectTrajectories: {
                    type: 'label',                                        
                    color: '#FF6363',  // smooth red
                },               
                uncertaintyRegion: {
                    type: 'checkbox',                   
                    value: false,
                    color: '#FFF000', // yellow
                },
                uncertaintyDensity: {
                    type: 'slider',                   
                    label: true,
                    value: 1,
                    min: 0,
                    max: 'numberOfJds',
                    step: 1, 
                    parent: 'uncertaintyRegion'                  
                },
                uncertaintyFactor: {
                    type: 'slider',                   
                    label: true,
                    value: 5,
                    min: 1,
                    max: 100,
                    step: 1, 
                    parent: 'uncertaintyRegion'
                },              
                trailPointSymbol: {
                    type: 'radio', 
                    color: '#FFFFFF',                                        
                    label: true,
                    value: 'triangle',
                    values: ['triangle','square','disc']
                },
                trailPointSize: {
                    type: 'radio', 
                    label: true,
                    value: 'medium',
                    values: ['small','medium','large']
                }                
            },           
            invertColors: false,
            printRequest: false,
            mag: {
                min: 0,
                value: 7,
                max: 7,
            },
            fov: {
                min: 0.1,
                value: 45,
                max: 90,
            },
            pathAngleLimit: 5
        }
    },
    visualisation: {
        options: {
            ovt: {
                objectsSettings: 'objectNames,objectNamesSize,objectDetails,diameter,magnitude,orbitLegend,horizontalGrid,horizontalPlane,keplerianOrbits,perturbedOrbits,perturbedOrbitPropagation,trailDurationYears,leadDurationYears,earthName,earthNameSize,earthOrbit,planetsNames,planetsNamesSize,planetsOrbits,milkyWayBackground,background,dsos,axisGuide',                
                export: 'img,webm',
                button: 'objectsSettings,invertColors,export',
                slider: 'zoom',  
            },
            fvt: {
                objectsSettings: 'objectNames,objectNamesSize,objectDetails,closestApproachPoint,asteroidsSizeFactor,sun,moon,asteroidOrbit,sunOrbit,moonOrbit,uncertaintyRegion,uncertaintyDensity,uncertaintyFactor,guidingArrows,horizontalGrid,horizontalPlane,geostationaryOrbit,arrowLegend,axisGuide,milkyWayBackground,background,dsos,lightEffect',                
                export: 'img,webm',
                button: 'objectsSettings,invertColors,export',
                slider: 'zoom',
            },
            sovt: {
                objectsSettings: 'objectNames,objectNamesSize,asteroidsSizeFactor,objectDetails,detectionPolar,detectionPolarProperties,orbitRange,elongationCone,elongationConeProperties,keplerianOrbits,perturbedOrbits,orbitLegend,horizontalGrid,horizontalPlane,earthName,earthNameSize,synodicEarthOrbit,earthOnScale,synodicPlanets,synodicPlanetsNames,synodicPlanetsNamesSize,synodicPlanetsOrbits,axisGuide',
                export: 'img,webm',
                button: 'objectsSettings,charts,invertColors,export',
                slider: 'zoom',  
            },
        },
        settings: {
            objectsSettings: {
                objectNames: {
                    type: 'checkbox',                   
                    value: true,
                    color: '#FFFFFF',  // white   
                },
                objectNamesSize: {
                    type: 'slider',                                        
                    value: 15,                    
                    min: 1,
                    max: 30,
                    step: 0.25, 
                    label: false,
                    parent: 'objectNames'      
                },
                keplerianOrbits: {
                    type: 'label',                                       
                    color: '#0F84DD',  // blue  
                },
                perturbedOrbits: {
                    type: 'label',                                       
                    color: '#FF6363',  // red
                },
                objectDetails: {
                    type: 'checkbox',                   
                    value: true
                },
                diameter: {
                    type: 'slider',
                    min: 0,
                    max: 1000000,
                    step: 1,
                    value:[0,1000000],  
                    units: 'm',  
                    merge: 300000,             
                },
                magnitude: {
                    type: 'slider',
                    min: -10.0,
                    max: 30.0,
                    step: 0.1,
                    value:[-10.0,30.0],  
                    units: 'mag',
                    merge: 15,
                },
                horizontalGrid: {
                    type: 'checkbox',                      
                    value: false,
                    units: true,
                },
                horizontalPlane: {
                    type: 'checkbox',                   
                    value: false,                  
                },
                perturbedOrbitPropagation: {
                    type: 'checkbox',                   
                    value: false,                       
                    disabled: true,               
                },                     
                trailDurationYears: {
                    type: 'date',
                    min: 'originCalculations',
                    value: 2.0,
                    max: 'now', 
                    back: true,  
                    parent: 'perturbedOrbitPropagation'               
                },
                leadDurationYears: {
                    type: 'date',
                    min: 'now',
                    value: 2.0,
                    max: 'maxCalculations',  
                    parent: 'perturbedOrbitPropagation'                  
                },
                orbitLegend: {
                    type: 'checkbox',                   
                    value: true,                  
                },   
                earthName: {
                    type: 'checkbox',                   
                    value: true,       
                    color: '#FFFFFF',  // white
                    tooltipInfo: {
                        tools: ['sovt'],
                        key: 'earthName',
                    },
                },   
                earthNameSize: {
                    type: 'slider',                                        
                    value: 15,                    
                    min: 1,
                    max: 30,
                    step: 0.25, 
                    label: false,
                    parent: 'earthName'      
                },        
                earthOrbit: {
                    type: 'checkbox',                   
                    value: true,  
                    color: '#F2EDEF',                                         
                },
                synodicEarthOrbit: {
                    type: 'checkbox',                   
                    value: true,  
                    color: '#444444',                                         
                },  
                planetsNames: {
                    type: 'checkbox',                   
                    value: true,       
                    color: '#FFFFFF',  // white              
                },  
                planetsNamesSize: {
                    type: 'slider',                                        
                    value: 15,                    
                    min: 1,
                    max: 30,
                    step: 0.25, 
                    label: false,
                    parent: 'planetsNames'      
                },           
                planetsOrbits: {
                    type: 'checkbox',                   
                    value: true, 
                    color: '#F2EDEF',                                          
                },
                sun: {
                    type: 'checkbox',                   
                    value: true,                                                         
                },                              
                sunOrbit: {
                    type: 'checkbox',                   
                    value: true,                                     
                    color: '#FFF000',                                         
                },
                moon: {
                    type: 'checkbox',                   
                    value: true,                                                         
                },                       
                moonOrbit: {
                    type: 'checkbox',                   
                    value: true,                                      
                    color: '#666666',                                         
                },              
                synodicPlanets: {
                    type: 'checkbox',
                    value: false,
                },
                synodicPlanetsNames: {
                    type: 'checkbox',                   
                    value: true,
                    color: '#FFFFFF',  // white   
                    parent: 'synodicPlanets'
                },
                synodicPlanetsNamesSize: {
                    type: 'slider',                                        
                    value: 15,                    
                    min: 1,
                    max: 30,
                    step: 0.25, 
                    label: false,
                    parent: 'synodicPlanetsNames'      
                },
                synodicPlanetsOrbits: {
                    type: 'checkbox',                   
                    value: true, 
                    color: '#444444',
                    parent: 'synodicPlanets'                                        
                },
                arrowLegend: {
                    type: 'checkbox',                   
                    value: true,
                },                                
                geostationaryOrbit: {
                    type: 'checkbox',                   
                    value: false
                },                
                axisGuide: {
                    type: 'checkbox',                   
                    value: false
                },                
                asteroidOrbit: {
                    type: 'checkbox',                   
                    value: true,
                    color: '#0F84DD',
                },                
                asteroidsSizeFactor: {
                    type: 'slider',                   
                    label: true,
                    value: 6,
                    min: 0,
                    max: 8,
                    step: 0.01,                       
                },
                uncertaintyRegion: {
                    type: 'checkbox',                   
                    value: false,
                    color: '#FFF000', // yellow                    
                },
                uncertaintyDensity: {
                    type: 'slider',                   
                    label: true,
                    value: 1,
                    min: 0,
                    max: 'numberOfMaxJds',
                    step: 1, 
                    parent: 'uncertaintyRegion'
                },
                uncertaintyFactor: {
                    type: 'slider',                   
                    label: true,
                    value: 5,
                    min: 1,
                    max: 100,
                    step: 1, 
                    parent: 'uncertaintyRegion'
                },
                guidingArrows: {
                    type: 'checkbox',                   
                    value: true, 
                },
                closestApproachPoint: {
                    type: 'checkbox',                   
                    value: true
                },
                dsos: {
                    type: 'checkbox',                   
                    value: true
                },
                milkyWayBackground: {
                    type: 'checkbox',                   
                    value: true,
                    tooltip: 'The background colour can be changed if the Milky Way is disabled.'
                },              
                lightEffect: {
                    type: 'checkbox',                   
                    value: false
                }, 
                background: {
                    type: 'label',                                       
                    color: '#000000',  // white  0A111B                                
                },
                orbitRange: {
                    type: 'checkbox',
                    value: false,
                    tooltipInfo: {
                        tools: ['sovt'],
                        key: 'objectOrbitRange',
                    },
                },
                detectionPolar: {
                    type: 'checkbox',
                    value: true,
                },
                detectionPolarProperties: {
                    type: 'multiple',
                    parent: 'detectionPolar',
                    children: {
                        transparency: {
                            type: 'slider',                   
                            label: true,
                            value: 0.15,
                            min: 0,
                            max: 1,
                            step: 0.05, 
                            decimalFormat: 2,
                        },
                        color: {
                            type: 'colorpicker',                   
                            label: true,
                            value: '#FFD700',
                        },
                    },
                },
                elongationCone: {
                    type: 'checkbox',
                    value: false,
                },
                elongationConeProperties: {
                    type: 'multiple',
                    parent: 'elongationCone',
                    children: {
                        coneAngle: {
                            type: 'slider',                   
                            label: true,
                            value: 0.5,
                            min: 0,
                            max: 90,
                            step: 0.5, 
                            decimalFormat: 1,
                            baseline: true,
                        },
                        transparency: {
                            type: 'slider',                   
                            label: true,
                            value: 0.15,
                            min: 0,
                            max: 1,
                            step: 0.05, 
                            decimalFormat: 2,
                        },
                        color: {
                            type: 'colorpicker',                   
                            label: true,
                            value: '#7D7D7D',
                        },
                    },
                },
                earthOnScale: {
                    type: 'checkbox',
                    value: false,
                    tooltipInfo: {
                        tools: ['sovt'],
                        key: 'earthOnScale',
                    },
                },
            },          
            invertColors: {
                all: false,
                sun: false,
                earth: false,
                multipleObjects: false,
                background: false,
                userInterface: false,
            },
            printRequest: false,
            zoom: {
                min: 0.01,
                value: 2.5,
                max: 16,
            },
            particleSize: 7,
            originDateCalculations: '1950-01-01',
            maxYearsCalculations: 100.0,
            maxSavedPerturbedObjects: 10,
            points_density: 10000,//The logic of this selection is that in .ele file the timestep aoutside the close approach is one day, and during close
            //approach is one hour. Using this selection, we assure 27 points per day, which means a timestep smaller than one hour, avoiding the plotting
            //problems at close approach. Previously we had 1000 points.
            synodicOrbitCharts: false,
            NEAcolor: '#000000',
            nonNEAcolor: '#969696',
            NEOCPcolor: '#45b6fe',
        }
    },
    attributionText: 'NEO Coordination Centre. European Space Agency.',
    MilkyWayAttribution: 'Milky Way image:',
    filters: {
        database: {
            type: 'checkbox',
            options: 'ESA/PDO,NEOCP,JPL',
        },
        bodyType: {
            type: 'checkbox',
            options: 'Asteroid,Comet,Unconfirmed',
        },
        orbitType: {
            type: 'checkbox',
            options: 'Amor,Apollo,Aten,Atira,N/A',
        },
        risk: {
            type: 'radio',
            options: 'true,false',
        },
        priority: {
            type: 'checkbox',
            options: 'UR,NE,US,LP,0',
        },
        pha: {
            type: 'radio',
            options: 'true,false',
        },
        magnitude: {
            type: 'range',
            default: '23',
            defaultOperator: 'lt',
            min: '-30',
            max: '35',
        },
        sunElongation: {
            type: 'range',
            default: '50',
            defaultOperator: 'gt',
            min: '0',
            max: '180',
        },
        moonElongation: {
            type: 'range',
            default: '30',
            defaultOperator: 'gt',
            min: '0',
            max: '180',
        },
        galaxyLatitude: {
            type: 'range',
            default: '0',
            defaultOperator: 'gt',
            min: '0',
            max: '90',
        },
        skyMovement: {
            type: 'range',
            default: '100',
            defaultOperator: 'lt',
            min: '0',
            max: '240000',
        },
        densityOfStars: {
            type: 'minValue',
            default: '100',
            defaultOperator: 'lt',
            //min: '0',
            min: '100',
            max: null,
        },
        skyUncertainty: {
            type: 'range',
            //default: '3600',
            default: '0',
            defaultOperator: 'gt',
            min: '0',
            max: '648000',
        },
        elevation: {
            type: 'range',
            default: '20',
            defaultOperator: 'gt',
            min: '-90',
            max: '90',
        },
        moonglow: {
            type: 'range',
            default: '-5',
            defaultOperator: 'gt',
            min: '-5',
            max: '0',
        },
        nrOfDaysUnobserved: {
            type: 'minValue',
            default: '0',
            defaultOperator: 'gt',
            min: '0',
            max: null,
        },
        observationArc: {
            type: 'minValue',
            default: '0',
            defaultOperator: 'gt',
            min: '0',
            max: null,
        },
        NA: {
            type: 'checkbox',
            options: 'N/A',
        },
    },
    filtersEphemeridesMap: {
        densityOfStars: 'DEN_STARS',
        elevation: 'ELEV',
        galaxyLatitude: 'AST_GLAT',
        magnitude: 'MAG',
        moonElongation: 'MOOEL',
        moonglow: 'MOONGLOW',
        nrOfDaysUnobserved: 'DAYS_UNOBSERVED',
        observationArc: 'ARC',
        skyMovement: 'SKY_MOV',
        skyUncertainty: 'ERR_A',
        sunElongation: 'ELON',
    },
    orbitVisualisation: {
        selects: {
            neoGroups: {
                id: 'group',
                label: 'neoGroups',
            },
            asteroidFamilies: {
                id: 'family',
                label: 'asteroidFamilies',
            },
            spectralClasses: {
                id: 'tax',
                label: 'spectralClasses',
            },
            neoccObservationalPriorityList: {
                id: 'priority_list',
                label: 'neoccObservationalPriorityList',
                options: [
                    {
                        value: 'UR',
                        label: ''
                    }, {
                        value: 'NE',
                        label: ''
                    }, {
                        value: 'US',
                        label: ''
                    }, {
                        value: 'LP',
                        label: ''
                    }
                ]
            }
        }
    },
    synodicOrbitVisualisation: {
        parameterInputs: {
            magnitudeLimit: {
                id: 'magnitudeLimit',
                labelId: 'magnitudeLimit',
                unitId: null,
                emptyMsgId: 'provideTelescopeMagnitude',
                type: 'number',
                isAlwaysVisible: true,
                tooltip: 'limitingVisualMagnitude',
                validation: {
                    schema: 'limitedNumber',
                    min: -30,
                    max: 32,
                    decimal: 1,
                    step: 0.1
                },
            },
        },
    },
    syntheticObjectsConfiguration: {
        availableTools: ['scd'],
        parametersInputs: {
            default: {
                objectName: {
                    id: 'objectName',
                    labelId: 'objectName',
                    unitId: null,
                    type: 'text',
                    validation: {
                        schema: 'unique',
                        maxLength: 200,
                    },
                    errorMsgId: 'notUniqueObjectName',
                },
                referenceEpoch: {
                    id: 'referenceEpoch',
                    labelId: 'referenceEpoch',
                    unitId: null,
                    type: 'date',
                },
                perihelionDistance: {
                    id: 'perihelionDistance',
                    labelId: 'perihelionDistance',
                    unitId: 'au',
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: 0.01,
                        max: 100,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
                aphelionDistance: {
                    id: 'aphelionDistance',
                    labelId: 'aphelionDistance',
                    unitId: 'au',
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: 0.1,
                        max: 100,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
                inclination: {
                    id: 'inclination',
                    labelId: 'inclination',
                    unitId: 'degrees',
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: 0,
                        max: 180,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
                rightAscension: {
                    id: 'rightAscension',
                    labelId: 'rightAscension',
                    unitId: 'degrees',
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: 0,
                        max: 360,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
                perihelionArgument: {
                    id: 'perihelionArgument',
                    labelId: 'perihelionArgument',
                    unitId: 'degrees',
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: 0,
                        max: 360,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
                meanAnomaly: {
                    id: 'meanAnomaly',
                    labelId: 'meanAnomaly',
                    unitId: 'degrees',
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: 0,
                        max: 360,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
                absoluteMagnitude: {
                    id: 'absoluteMagnitude',
                    labelId: 'absoluteMagnitude',
                    unitId: null,
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: -30,
                        max: 32,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
                slopeParameter: {
                    id: 'slopeParameter',
                    labelId: 'slopeParameter',
                    unitId: null,
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: 0,
                        max: 1,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
            },
            fvt: {
                objectName: {
                    id: 'objectName',
                    labelId: 'objectName',
                    unitId: null,
                    type: 'text',
                    validation: {
                        schema: 'unique',
                        maxLength: 200,
                    },
                    errorMsgId: 'notUniqueObjectName',
                },
                pericentreDistance: {
                    id: 'pericentreDistance',
                    labelId: 'pericentreDistance',
                    unitId: 'au',
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: 0,
                        max: 1000,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
                eccentricity: {
                    id: 'eccentricity',
                    labelId: 'eccentricity',
                    unitId: null,
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: 0,
                        max: 1000,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
                inclination: {
                    id: 'inclination',
                    labelId: 'inclination',
                    unitId: 'degrees',
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: 0,
                        max: 180,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
                ascendingNodeLongitude: {
                    id: 'ascendingNodeLongitude',
                    labelId: 'ascendingNodeLongitude',
                    unitId: 'degrees',
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: 0,
                        max: 360,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
                pericentreArgument: {
                    id: 'pericentreArgument',
                    labelId: 'pericentreArgument',
                    unitId: 'degrees',
                    type: 'number',
                    validation: {
                        schema: 'limitedNumber',
                        min: 0,
                        max: 360,
                        //decimal: 10,
                        step: 0.1
                    },
                    errorMsgId: 'limitedNumber',
                },
                pericentrePassage: {
                    id: 'pericentrePassage',
                    labelId: 'pericentrePassage',
                    unitId: null,
                    type: 'date',
                },
            },
        },
    },
    mainBodiesParameters: {
        sun: {
            name: 'Sun',
            radius: 695508 / 149597871, // km -> AU, source: https://solarsystem.nasa.gov/solar-system/sun/by-the-numbers/
            period: 25.05, // in relation to Earths period
            beta: 90, // tilt - https://nssdc.gsfc.nasa.gov/planetary/factsheet/
            gamma0: 0, // ?
            jd0: 2451544.5, // starting JD
            lambda: 0, // ?
            type: null, // rocky, gas, null for sun
            atmosphere: false // has atmosphere
        },
        mercury: {
            name: 'Mercury',
            radius: 2439.4 / 149597871, // source: https://ssd.jpl.nasa.gov/planets/phys_par.html
            period: 58.646,
            beta: 90 - 0.034,
            gamma0: 0,
            jd0: 2451544.5,
            lambda: 252.2509,
            type: 'rocky',
            atmosphere: false
        },
        venus: {
            name: 'Venus',
            radius: 6051.8 / 149597871, // source: https://ssd.jpl.nasa.gov/planets/phys_par.html
            period: -243.018,
            beta: 90 - 177.4,
            gamma0: 0,
            jd0: 2451544.5,
            lambda: 181.9798,
            type: 'rocky',
            atmosphere: true
        },
        earth: {
            name: 'Earth',
            radius: 6371.0084 / 149597871, // source: https://ssd.jpl.nasa.gov/planets/phys_par.html
            period: 0.9972696322627929,
            geostationaryOrbit: 42164 / 149597871,
            beta: 90 - 23.4, // should be 90 due to using equatorial reference system
            gamma0: 0,
            jd0: 2451544.5, // 01-01-2000 00:00, source https://www2.mps.mpg.de/homes/fraenz/systems/systems3art.pdf
            lambda: 100.4664, // corresponds with jd0 as ecliptic longitude
            type: 'rocky',
            atmosphere: true
        },
        moon: {
            name: 'Moon',
            radius: 1737.4 / 149597871, // source: https://ssd.jpl.nasa.gov/sats/phys_par/
            period: 27.321661,
            beta: 90 - 6.7,
            gamma0: 0,
            jd0: 2451544.5,
            lambda: 0,
            type: null,
            atmosphere: false
        },
        mars: {
            name: 'Mars',
            radius: 3389.5 / 149597871, // source: https://ssd.jpl.nasa.gov/planets/phys_par.html
            period: 1.026,
            beta: 90 - 25.2,
            gamma0: 0,
            jd0: 2451544.5,
            lambda: 355.4329,
            type: 'rocky',
            atmosphere: false
        },
        jupiter: {
            name: 'Jupiter',
            radius: 69911 / 149597871, // source: https://ssd.jpl.nasa.gov/planets/phys_par.html
            period: 0.41354,
            beta: 90 - 3.1,
            gamma0: 0,
            jd0: 2451544.5,
            lambda: 34.3515,
            type: 'gas',
            atmosphere: true
        },
        saturn: {
            name: 'Saturn',
            radius: 58232 / 149597871, // source: https://ssd.jpl.nasa.gov/planets/phys_par.html
            period: 0.444,
            beta: 90 - 26.7,
            gamma0: 0,
            jd0: 2451544.5,
            lambda: 50.0774,
            type: 'gas',
            atmosphere: true
        },
        uranus: {
            name: 'Uranus',
            radius: 25362 / 149597871, // source: https://ssd.jpl.nasa.gov/planets/phys_par.html
            period: -0.718,
            beta: 90 - 97.8,
            gamma0: 0,
            jd0: 2451544.5,
            lambda: 314.0550,
            type: 'gas',
            atmosphere: true
        },
        neptune: {
            name: 'Neptune',
            radius: 24622 / 149597871, // source: https://ssd.jpl.nasa.gov/planets/phys_par.html
            period: 0.671,
            beta: 90 - 28.3,
            gamma0: 0,
            jd0: 2451544.5,
            lambda: 304.3486,
            type: 'gas',
            atmosphere: true
        },
    }
}
