<template>
    <div class="fvt__timeline" :class="{'with-config': configPanelOpen, 'disabled': !selectedCloseApproach}">
        <div class="fvt__timeline-buttons">
            <button class="circle default" @click="goToStart()">
                <i class="icon-skip-back" />
            </button>
            <button class="circle primary reverse" @click="togglePlay(true)">
                <i v-if="running && speed < 0" class="icon-pause" />
                <i v-else class="icon-play" />
            </button>
            <button class="circle primary" @click="togglePlay(false)">
                <i v-if="running && speed > 0" class="icon-pause" />
                <i v-else class="icon-play" />
            </button>
            <button class="circle default" @click="goToEnd()">
                <i class="icon-skip-forward" />
            </button>
        </div>

        <div class="fvt__timeline-slider" :class="{'with-marker': showMarker()}" :style="markerPosition">
            <div class="fvt__timeline-inputs">
                <div class="input start-date">
                    <span class="input-label">{{ $t.startDate }}:</span>
                    <span class="input-value">{{ formatDate(dateFrom) }}</span>
                </div>
            </div>
            <div class="fvt__timeline-slider__wrapper">
                <Tooltip v-if="showMarker()" position="top" :tooltipText="closestApproachPointTooltip()" style="left: var(--marker-position);">
                    <div class="fvt__timeline-slider__tooltip-content"></div>
                </Tooltip>
                <Slider v-if="from && to" :tooltips="false" v-model="now" :min="from.getTime()" :max="to.getTime()" @slide="sliderNowUpdate($event)" />
            </div>
           
        </div>

        <div class="fvt__timeline-inputs">
            <div class="input end-date">
                <span class="input-label">{{ $t.endDate }}:</span>
                <span class="input-value">{{ formatDate(dateTo) }}</span>
            </div>
            <div class="input current-date">
                <span class="input-label">{{ $t.currentDate }}:</span>
                <VueDatePicker 
                    :model-value="dateNow" 
                    :min-date="dateFrom" 
                    :max-date="dateTo" 
                    format="dd-MM-yyyy HH:mm" 
                    :clearable="false" 
                    :year-range="[dateFromToYear(),dateToToYear()]" 
                    auto-apply 
                    @update:model-value="currentDateUpdate"
                    text-input
                    utc="preserve"
                />
                
            </div>
            <div class="input">
                <span class="input-label">{{ $t.playbackSpeed }}:</span>
                <select @change="setSpeed($event.target.value)" :value="speed">
                    <option v-for="option of options" :key="option.value" :value="option.value" :hidden="option.hidden">{{ option.label }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import VisualisationService from '@/services/visualisation.service'
import SpacekitService from '@/services/spacekit.service'
import UtilsService from '@/services/utils.service'
import Slider from '@vueform/slider'
import Tooltip from '@/components/common/Tooltip'
import MemoryService from '@/services/memory.service'

export default {
    name: 'FlybyTimeLine',
    components: {
        Slider,
        Tooltip,
        VueDatePicker
    },
    data() {
        return {
            now: new Date(),
            running: false,
            from: null,
            to: null,
            dateFrom: '',
            dateTo: '',
            dateNow: '',
            options: [
                {value: '1', label: '1 day / second'},
                {value: '0.5', label: '12 hours / second'},
                {value: '0.25', label: '6 hours / second'},
                {value: '0.083', label: '2 hours / second'},
                {value: '0.041', label: '1 hour / second'},
                {value: '-0.041', label: '1 hour / second', hidden: 'true'},
                {value: '-0.083', label: '2 hours / second', hidden: 'true'},
                {value: '-0.25', label: '6 hours / second', hidden: 'true'},
                {value: '-0.5', label: '12 hours / second', hidden: 'true'},
                {value: '-1', label: '1 day / second', hidden: 'true'},
            ],
            speed: 1,
            marker: 0,
        }
    },
    computed: {
        configPanelOpen() {
            return VisualisationService.getSelectObjectsShow();
        },
        simulationNow() {
            return VisualisationService.getNow();
        },
        selectedCloseApproach() {
            return VisualisationService.getSelectedCloseApproach();
        },
        flybyClosestPoint() {
            return VisualisationService.getFlybyClosestPoint();
        },
        settings() {
            return VisualisationService.getSettings();
        },
        closestApproachPoint() {
            return this.settings && this.settings.objectsSettings && this.settings.objectsSettings.closestApproachPoint.value;
        },
        markerPosition() {
            return {
                '--marker-position': this.marker
            }
        }
    },
    methods: {
        dateFromToYear() {
            const dateFrom = new Date(this.dateFrom);
            const year = dateFrom.getFullYear();
            return year;
        },
        dateToToYear() {
            const dateTo = new Date(this.to);
            const year = dateTo.getFullYear();
            return year;
        },
        togglePlay(reverse) {
            this.running = !this.running;

            if (reverse && this.speed > 0) {
                this.speed = this.speed * -1;
                this.setSpeed();
                this.running = true;
            }

            if (!reverse && this.speed < 0) {
                this.speed = this.speed * -1;
                this.setSpeed();
                this.running = true;
            }

            if (this.running) {
                SpacekitService.play();
            } else {
                SpacekitService.pause();
            }
        },
        goToStart() {
            this.now = this.from.getTime();
        },
        goToEnd() {
            this.now = this.to.getTime();
        },

        currentDateUpdate(event) {
            const newDate = new Date(event);
            SpacekitService.setDate(newDate);
            this.now = newDate;
            this.dateNow = UtilsService.dateToStringTechnical(newDate, true);
        },
        sliderNowUpdate(event) {
            const newDate = new Date(event);
            SpacekitService.setDate(newDate);
            this.dateNow = UtilsService.dateToStringTechnical(newDate, true);
            VisualisationService.setCurrentDate(newDate);
        },
        setSpeed(value = null) {
            if (value) {
                if (this.speed < 0) {
                    this.speed = value * -1;
                } else {
                    this.speed = value;
                }
            }
            SpacekitService.setSpeed(this.speed);
            VisualisationService.setPlaybackSpeedFVT(this.speed);
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return UtilsService.dateToString(date, true);
        },
        calculateMarkerPosition(jd) {
            const datesSpread = this.to - this.from;
            const closestPointPosition = UtilsService.julianToTimestamp(jd) - this.from;
            const markerPercentage = ((closestPointPosition / datesSpread) * 100).toFixed(2);
            this.marker = markerPercentage + '%';
        },
        showMarker() {
            return this.flybyClosestPoint && this.closestApproachPoint;
        },
        closestApproachPointTooltip() {
            if (this.selectedCloseApproach) {
                return `${this.$t.closestApproachOf} ${this.selectedCloseApproach.name || this.selectedCloseApproach.objectName} ${this.$t.to} ${this.selectedCloseApproach.mainBody.name}: ${this.formatDate(UtilsService.julianToTimestamp(this.flybyClosestPoint))}`;
            }
        },
        restoreCloseApproach(){
            const selectedCloseApproach = VisualisationService.getSelectedCloseApproach();
            if (selectedCloseApproach){
                SpacekitService.pause();
                this.from = new Date(UtilsService.julianToTimestamp(selectedCloseApproach.jds[0] + 2400000.5));
                this.to = new Date(UtilsService.julianToTimestamp(selectedCloseApproach.jds[selectedCloseApproach.jds.length - 1] + 2400000.5));
                this.dateFrom = UtilsService.dateToStringTechnical(this.from, true);
                this.dateTo = UtilsService.dateToStringTechnical(this.to, true);

                setTimeout(() => {
                    this.now = this.from;
                    this.dateNow = UtilsService.dateToStringTechnical(this.from, true);
                    SpacekitService.setDate(this.from);
                    VisualisationService.setNow(this.now);
                }, 0);
                SpacekitService.updateAsteroidsSizeFactor(VisualisationService.getSettings().objectsSettings.asteroidsSizeFactor.value);
            }
        },
        restoreSimulationTime(previousDateFVT){
            this.now = new Date(previousDateFVT);
            SpacekitService.setDate(this.now);
            VisualisationService.setNow(this.now);
            this.dateNow = UtilsService.dateToStringTechnical(this.now, true);
        }
    },
    watch: {
        dateNow(newVal, oldVal) {
            VisualisationService.setCurrentDate(newVal);
            VisualisationService.setPreviousTimeFVT(newVal);
            const vt = window['vt'];
            if (vt.flybyScene && vt.flybyScene.length) {
                const jd0 = UtilsService.dateToJulian(oldVal);
                const jd1 = UtilsService.dateToJulian(newVal);
                if (jd0 !== jd1) {
                    VisualisationService.updateRotationAngle(jd0, jd1);
                }
            }
        },
        simulationNow(newVal) {
            if (this.running) {
                //this.now = newVal;
                if ((newVal >= this.to && this.speed > 0) || (newVal <= this.from && this.speed < 0)) {
                    if (newVal >= this.to && this.speed > 0){
                        newVal = this.to;
                        this.now = newVal;
                    }
                    if (newVal <= this.from && this.speed < 0){
                        newVal = this.from;
                        this.now = newVal;
                    }
                    SpacekitService.setDate(this.now);
                    VisualisationService.setNow(this.now);
                    SpacekitService.pause();
                    this.running = false;
                }
                else{
                    this.now = newVal; 
                }
            }
            this.dateNow = UtilsService.dateToStringTechnical(newVal, true);
        },
        selectedCloseApproach(newVal) {
            if (!newVal) return;
            SpacekitService.pause();
            this.from = new Date(UtilsService.julianToTimestamp(newVal.jds[0] + 2400000.5));
            this.to = new Date(UtilsService.julianToTimestamp(newVal.jds[newVal.jds.length - 1] + 2400000.5));
            this.dateFrom = UtilsService.dateToStringTechnical(this.from, true);
            this.dateTo = UtilsService.dateToStringTechnical(this.to, true);

            setTimeout(() => {
                this.now = this.from;
                this.dateNow = UtilsService.dateToStringTechnical(this.from, true);
                SpacekitService.setDate(this.from);
                VisualisationService.setNow(this.now);
            }, 0);
        },
        flybyClosestPoint(newVal) {
            if (newVal) {
                this.calculateMarkerPosition(newVal);
            }
        }
    },
    mounted() {
        const previousDateFVT = VisualisationService.getPreviousTimeFVT();
        const previousSpeedFVT = VisualisationService.getPlaybackSpeedFVT();

        if ((previousSpeedFVT) && (this.speed !== previousSpeedFVT))
            this.speed = previousSpeedFVT;

        this.from = UtilsService.dateAddDays(new Date(), -5);
        this.to = UtilsService.dateAddDays(new Date(), 5);
        this.dateFrom = UtilsService.dateToStringTechnical(this.from, true);
        this.dateTo = UtilsService.dateToStringTechnical(this.to, true);
        this.now = this.from;
        SpacekitService.setDate(this.now);
        SpacekitService.setSpeed(this.speed);    
        this.dateNow = this.dateFrom;

        if (VisualisationService.getSelectedCloseApproach()){
            this.restoreCloseApproach();
            setTimeout(() => {  
                this.restoreSimulationTime(previousDateFVT); 
            },3000);
        }

        setTimeout(() => {            
            const loadedState = {...MemoryService.getToolState()};
            const newdate = new Date(loadedState.activeCurrentDate);

            if((loadedState.activeCurrentDate) && (this.now !== newdate)){
                VisualisationService.setCurrentDate(loadedState.activeCurrentDate);
                this.now = newdate;
                SpacekitService.setDate(newdate);
                VisualisationService.setNow(newdate);
                this.dateNow = UtilsService.dateToStringTechnical(newdate, true);
            }

            if ((loadedState.speedFVT) && (this.speed !== loadedState.speedFVT)){
                VisualisationService.setPlaybackSpeedFVT(loadedState.speedFVT);
                this.speed = loadedState.speedFVT;
                SpacekitService.setSpeed(this.speed);
            }

        }, 4000);

    }
}

</script>

<style>

</style>
