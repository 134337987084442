<template>
    <div v-if="closeApproaches.length" class="fvt__close-approaches-title">
        <span>{{ $t.closeUpproachesFound }}</span>
        <Tooltip position="bottom" :tooltipText="$keys.tooltips.closeApproachesFound"><i class="icon-info" /></Tooltip>
    </div>
    <div v-if="closeApproaches.length" class="fvt__close-approaches-table d-table">
        <div class="d-table-header">
            <div class="d-table-cell" :class="'sort '+name" @click="sortByColumn('name')">
                {{ $t.objectName }}
            </div>
            <div class="d-table-cell" :class="'sort '+datetime" @click="sortByColumn('datetime')">
                {{ $t.approachDate }}
            </div>
            <div v-show="!mainBody" class="d-table-cell" :class="'sort '+body" @click="sortByColumn('body')">
                {{ $t.mainBody }}
            </div>
            <div v-if="areMissingInArchive()" class="d-table-cell"></div>
        </div>
        <div v-for="closeApproach of closeApproaches" :key="closeApproach.mjd" class="d-table-row" :class="{active: isActive(closeApproach), units: !closeApproach.infvtarchive}" @click="selectCloseApproach(closeApproach)">
            <div class="d-table-cell">
                <span>
                    {{ displayName(closeApproach) }}
                </span>
            </div>
            <div class="d-table-cell">
                {{ displayDate(closeApproach) }}
            </div>
            <div v-show="!mainBody" class="d-table-cell">
                {{ displayMainBody(closeApproach) }}
            </div>
            <div v-if="areMissingInArchive()" class="d-table-cell not-in-archive">
                <Tooltip v-if="!closeApproach.infvtarchive" position="left" :tooltipText="`${$t.flybyDataNotAvailable}`"><i class="icon-info" /></Tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import VisualisationService from '@/services/visualisation.service'
import ObjectsService from '@/services/objects.service'
import UtilsService from '@/services/utils.service'
import Tooltip from '@/components/common/Tooltip'

export default {
    name: 'FlybyCloseApproachesTable',
    data() {
        return {
            name: 'none',
            datetime: 'none',
            body: 'none'
        }
    },
    components: {
        Tooltip
    },
    computed: {
        mainBody() {
            return VisualisationService.getMainBody();
        },
        closeApproaches() {
            return VisualisationService.getCloseApproaches();
        },
        selectedCloseApproach() {
            return VisualisationService.getSelectedCloseApproach();
        },
        orbitActiveObject() {
            return VisualisationService.getOrbitActiveObject();
        },
    },
    methods: {
        displayName(item) {
            return ObjectsService.objectNumberAndName(item);
        },
        displayDate(item) {
            const timestamp = UtilsService.julianToTimestamp(item.mjd*1 + 2400000.5);
            return UtilsService.momentUtcDate(timestamp, true);
        },
        displayMainBody(item) {
            return item.mainBody ? item.mainBody.name : 'Earth';
        },
        selectCloseApproach(closeApproach) {
            if (!closeApproach.infvtarchive) return;
            if (!this.selectedCloseApproach || this.selectedCloseApproach.mjd !== closeApproach.mjd) {
                VisualisationService.setSelectedCloseApproach(closeApproach);
            }
        },
        isActive(closeApproach) {
            if (!this.selectedCloseApproach) {
                return false;
            } else {
                return closeApproach.mjd === this.selectedCloseApproach.mjd;
            }
        },
        areMissingInArchive() {
            return this.closeApproaches && this.closeApproaches.filter(e => e.infvtarchive === false).length;
        },
        getNextSortingOrder(currentOrder) {
            const orders = ['none', 'asc', 'desc'];
            if (currentOrder === 'desc') { return 'none'}
            const currentIndex = orders.indexOf(currentOrder);
            return orders[currentIndex + 1];
        },
        resetOtherSortingColumns(param) {
            const sortingColumns = ['name', 'datetime', 'body'];
            sortingColumns.forEach(column => {
                if (column !== param) {
                    this[column] = 'none';
                }
            });

        },
        sortAsc(a, b) {
            if (a < b) {
                return -1;
            }
            if ( a > b) {
                return 1;
            }
            return 0;
        },
        sortDesc(a, b) {
            if (b < a) {
                return -1;
            }
            if ( b > a) {
                return 1;
            }
            return 0;
        },
        sortByColumn(param) {
            const _this = this;
            const sortingRule = this.getNextSortingOrder(this[param]);
            this.resetOtherSortingColumns(this[param])
            this[param] = sortingRule;
            switch (sortingRule) {
                case 'none':
                    this.closeApproaches.sort(function(a, b) {
                        return _this.sortAsc(a.datetime, b.datetime);
                    });
                break;
                case 'asc':
                    if (param === 'body') {
                        this.closeApproaches.sort(function(a, b) {
                            return _this.sortAsc(a.mainBody.name, b.mainBody.name);
                        });
                    } else {
                        this.closeApproaches.sort(function(a, b) {
                           return _this.sortAsc(a[param], b[param]);
                        });
                    }
                break;
                case 'desc':
                    if (param === 'body') {
                            this.closeApproaches.sort(function(a, b) {
                            return _this.sortDesc(a.mainBody.name, b.mainBody.name);
                        });
                    } else {
                        this.closeApproaches.sort(function(a, b) {
                            return _this.sortDesc(a[param], b[param]);
                        });
                    }
                break;
            }
        }
    },
    mounted() {
        /*
        if (this.selectedCloseApproach && !this.orbitActiveObject) {
            VisualisationService.setSelectedCloseApproach(this.selectedCloseApproach);
        }
        */
    }
}
</script>

<style>

</style>