<template>
    <div class="input">
        <button class="primary search-button" :class="{disabled: isBtnDisabled}" @click="mode === 'user-defined' ? compute() : search()" :disabled="isBtnDisabled">{{ mode === 'user-defined' ? $t.compute : $t.search }}</button>
    </div>
</template>

<script>
import VisualisationService from '@/services/visualisation.service';
import SyntheticObjectsService from '@/services/synthetic-objects.service';

export default {
    name: 'FlybySearch',
    props: {
        mode: String,
        isBtnDisabled: Boolean,
    },
    methods: {
        search() {
            VisualisationService.searchCloseApproaches();
        },
        compute() {
            VisualisationService.setSelectedCloseApproach(SyntheticObjectsService.getSyntheticFvtObjectsList()[0]);
        }
    }
}
</script>

<style>

</style>