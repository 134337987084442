<template>
    <div class="sovt__selector">
        <label class="sovt__selector-label" for="focusSelector">{{ $t.focusOn }}</label>
        <select
            @change="handleFocusOn($event.target.value)"
            :value="sovtFocusedObject"
            name="focusSelector"
            id="focusSelector"
            class="sovt__selector-select"
        >
            <option
                v-for="option of focusOptionsList"
                :key="option.value"
                :value="option.value"
                :disabled="option.disabled"
                :hidden="option.disabled"
            >
                {{ option.label }}
            </option>
        </select>
    </div>
</template>

<script>
import ObjectsService from "@/services/objects.service";
import SynodicCalculationService from "@/services/synodic-calculation.service";
import SyntheticObjectsService from "@/services/synthetic-objects.service";
import VisualisationService from "@/services/visualisation.service";

export default {
    name: "SynodicOrbitFocusSelector",
    data() {
        return {
            focusDefaultOptions: [
                {
                    value: "",
                    label: "Please select",
                    disabled: true,
                },
                {
                    value: "sun",
                    label: "Sun",
                },
                {
                    value: SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME,
                    label: "Earth",
                },
            ],
        };
    },
    computed: {
        focusOptionsList() {
            if (!this.selectedCalculatedObject || !this.selectedObjectDisplayName) {
                return this.focusDefaultOptions;
            }
            if (!this.selectedObjectDisplayName) {
                return this.focusDefaultOptions;
            }
            const selectedObjectData = {
                value: VisualisationService.getSelectedSynodicObjectName(),
                label: this.selectedObjectDisplayName,
            };
            return [...this.focusDefaultOptions, selectedObjectData];
        },

        selectedCalculatedObject() {
            return SynodicCalculationService.getSelectedCalculatedObject();
        },

        selectedObjectName() {
            return VisualisationService.getSelectedSynodicObjectName();
        },

        selectedObjectDisplayName() {
            const objectsList = ObjectsService.getFinalAsteroidsList();
            const syntheticObjectsList = SyntheticObjectsService.getSyntheticObjectsList();
            const objects = objectsList.concat(syntheticObjectsList);
            if (!objects.length) {
                return null;
            }
            const selectedObjectData = objects.find((object) => {
                const isSynthetic = !!object.objectName;
                if (isSynthetic) {
                    return object.designator === VisualisationService.getSelectedSynodicObjectName();
                }
                return (
                    SynodicCalculationService.getDesignator(object) ===
                    VisualisationService.getSelectedSynodicObjectName()
                );
            });
            if (!selectedObjectData) {
                return null;
            }
            return selectedObjectData.objectName || ObjectsService.objectNumberAndName(selectedObjectData);
        },

        sovtFocusedObject() {
            return VisualisationService.getSovtFocusedObject();
        },
    },
    methods: {
        handleFocusOn(focusObject) {
            VisualisationService.setSovtFocusedObject(focusObject);
        },
    },
    watch: {
        sovtFocusedObject(focusedObject) {
            VisualisationService.handleSovtFocusOnChange(focusedObject);
        },

        selectedObjectName() {
            const nonObjectFocusSelectors = this.focusDefaultOptions.map((option) => option.value);
            if (!nonObjectFocusSelectors.includes(this.sovtFocusedObject)) {
                this.handleFocusOn("sun");
            }
        },
    },
};
</script>

<style lang="scss">
@import "@/styles/sovt/focus-selector.scss";
</style>
