<template>
    <div class="fvt__main-bodies input">
        <span class="input-label">
            {{ $t.mainBody }}:
        </span>
        <select @change="onChange($event.target.value)">
            <option value="" selected="selected">{{ $t.allMainBodies }}</option>
            <option v-for="mainBody of mainBodies" :key="mainBody.id" :value="mainBody.id" :selected="selected && mainBody.id === selected.id">
                {{ mainBody.name }}
             </option>
        </select>
    </div>
</template>

<script>
import SyntheticObjectsService from '@/services/synthetic-objects.service';
import VisualisationService from '@/services/visualisation.service'

export default {
    name: 'FlybyMainBodySelector',
    data() {
        return {
            mainBodies: [],
        }
    },
    computed: {
        selected() {
            return VisualisationService.getMainBody();
        },
    },
    methods: {
        onChange(id) {
            VisualisationService.setIsNoApproachFoundMsgVisible(false);
            SyntheticObjectsService.setIsSyntheticObjectFlybyAboveLdLimit(false);
            if (id === '') {
                VisualisationService.setMainBody(null);
            } else {
                const mainBody = this.mainBodies.filter(e => e.id === id)[0];
                VisualisationService.setMainBody(mainBody);
            }

        }
    },
    mounted() {
        VisualisationService.getMainBodies().then(mainBodies => {
            this.mainBodies = mainBodies;
        });
    }
}
</script>

<style>

</style>